<template>
  <div>
    <div class="modal_header">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_user") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                @click="save()"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                type="success"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      class="aticler_m"
      v-loading="loadRole"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content">
            <div class="imgUpload">
              <div class="wrapper-block">
                <label for="">
                  <input type="file" @change="uploadImage($event)" id="file" />
                </label>
              </div>
              <div id="custom-upload" v-if="form.image">
                <img :src="form.image" id="image" />
              </div>
            </div>

            <el-form-item :label="columns.name.title" prop="name">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item :label="columns.surname.title" prop="surname">
              <el-input v-model="form.surname"></el-input>
            </el-form-item>
            <el-form-item :label="columns.patronymic.title" prop="patronymic">
              <el-input v-model="form.patronymic"></el-input>
            </el-form-item>

            <el-form-item :label="columns.specialties.title">
              <el-select
                v-model="specialties"
                multiple
                :placeholder="columns.specialties.title"
              >
                <el-option
                  v-for="item in specialties_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>

        <el-col :span="8" class="mt14">
          <div class="grid-content">
            <el-form-item :label="columns.email.title" prop="email">
              <el-input v-model="form.email" placeholder="mail@"></el-input>
            </el-form-item>
            <el-form-item :label="columns.phone.title" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="998(__) ___-__-__"
              ></el-input>
            </el-form-item>
            <el-form-item :label="columns.phone_two.title" prop="phone_two">
              <el-input
                v-model="form.phone_two"
                placeholder="998(__) ___-__-__"
              ></el-input>
            </el-form-item>
            <el-form-item :label="columns.password.title">
              <el-input
                v-model="form.password"
                :placeholder="$t('message.please_enter_parol')"
                show-password
              ></el-input>
            </el-form-item>

            <!-- <el-form-item :label="columns.doctor_services.title">
                            <el-select v-model="DoctorServices" multiple filterable :placeholder="columns.doctor_services.title">
                                <el-option v-for="item in services_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item> -->
          </div>
        </el-col>

        <el-col :span="8" class="mt14">
          <div class="grid-content">
            <el-form-item :label="columns.gender.title" prop="gender">
              <el-select v-model="form.gender" placeholder="Пол">
                <el-option label="Н/О" value="no"></el-option>
                <el-option :label="$t('message.male')" value="men"></el-option>
                <el-option
                  :label="$t('message.female')"
                  value="women"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item :label="columns.id_code.title" prop="id_code">
              <el-input
                v-model="form.id_code"
                :placeholder="$t('message.code_id')"
              ></el-input>
            </el-form-item>

            <div class="d-flex align-items-center">
              <el-form-item :label="columns.role_id.title" prop="role_id">
                <el-select
                  v-model="form.role_id"
                  :placeholder="$t('message.please_choose_role')"
                >
                  <el-option
                    v-for="role in roles"
                    :key="role.id"
                    :label="role.name"
                    :value="role.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-button class="minadd ml10"  @click="tabAddRole = true" icon="el-icon-circle-plus-outline"></el-button> -->
            </div>
          </div>
        </el-col>
        <!-- end col -->
        <el-col :span="24">
          <el-tree
            class="filter-tree"
            :data="data"
            show-checkbox
            node-key="id"
            :filter-node-method="filterNode"
            ref="items"
            @node-click="handleNodeClick"
            :expand-on-click-node="true"
          >
            <span class="custom-tree-node" slot-scope="{ node, data }">
              <span class="aaa my-tree-khan">
                <div>{{ data.name }}</div>
                <select
                  :placeholder="$t('message.class')"
                  v-model="data.amount_type"
                  @change="changes(node, data)"
                  :disabled="!node.checked"
                  size="small"
                  class="my-kh ml-3"
                  style="border: 1px solid #dcdfe6; border-radius: 4px"
                >
                  <option
                    v-for="item in classes"
                    :key="item.name"
                    :label="item.name"
                    :value="item.type"
                  ></option>
                </select>
                <input
                  :disabled="!data.amount_type"
                  v-model="data.amount"
                  @change="changes(node, data)"
                  :placeholder="'sum'"
                  ref="'data_amount'+ node.id"
                  class="my-kh el-input__inner2"
                  size="small"
                />
              </span>
            </span>
          </el-tree>
        </el-col>

        <!-- HOZIRCHA TO'XTATILGHAN -->
        <!-- <el-col :span="24">
                    <div class="grid-content">
                        <el-tabs v-model="form.activetabs" @tab-click="handleClick" class="ifoData mb20" type="border-card">
                            <el-tab-pane label="Документ"  name="first">

                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="Документ">
                                                <el-select v-model="form.document">
                                                    <el-option label="наружная реклама" value="shanghai"></el-option>
                                                    <el-option label="Zone two" value="beijing"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    
                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="Серия и номер ">
                                                <el-input v-model="form.seriesNumber" placeholder="__ ____"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    
                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="ИНН номер">
                                                <el-input v-model="form.inn" placeholder="_______"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    
                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="Дата рождения">
                                                <el-date-picker
                                                v-model="form.data2"
                                                type="date"
                                                placeholder="__.__.____">
                                                </el-date-picker>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    
                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="Орган выдачи ">
                                                <el-input v-model="form.issuingAuthority"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    
                                    <el-col :span="12">
                                        <el-upload
                                            class="upload-demo myupload-demoR"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :on-preview="handlePreview"
                                            :on-remove="handleRemove"
                                            :file-list="fileList"
                                            list-type="picture">
                                            <el-button size="small" type="primary">Click to upload</el-button>
                                            
                                        </el-upload>
                                    </el-col>
                                    
                                </el-row>
                                

                            </el-tab-pane>
                            <el-tab-pane label="Адрес регистрации" name="second">

                                <el-row :gutter="20">

                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="Город">
                                                <el-select v-model="form.city">
                                                    <el-option label="наружная реклама" value="shanghai"></el-option>
                                                    <el-option label="Zone two" value="beijing"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    



                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="Регион">
                                                <el-input v-model="form.region"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    

                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="Улица">
                                                <el-input v-model="form.street"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    

                                    <el-col :span="12">
                                        <div class="grid-content">
                                            <el-form-item label="Район">
                                                <el-input v-model="form.area"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    

                                    <el-col :span="4">
                                        <div class="grid-content">
                                            <el-form-item label="Дом">
                                                <el-input v-model="form.house"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    

                                    <el-col :span="4">
                                        <div class="grid-content">
                                            <el-form-item label="Корпус">
                                                <el-input v-model="form.body"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    

                                    <el-col :span="4">
                                        <div class="grid-content">
                                            <el-form-item label="Квартира">
                                                <el-input v-model="form.flat"></el-input>
                                            </el-form-item>
                                        </div>
                                    </el-col>
                                    

                                </el-row>
                                

                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-col> -->
        <!-- HOZIRCHA TO'XTATILGHAN -->
      </el-row>
      <!-- end row -->
    </el-form>
    <!-- end form -->

    <!--starting components modal  -->
    <el-drawer
      class="popups_title"
      :append-to-body="true"
      :visible.sync="tabAddRole"
      direction="rtl"
      size="75%"
    >
      <AddRole></AddRole>
    </el-drawer>
    <!--starting components modal  -->
  </div>
</template>

<script>
import AddRole from "./components/addRole.vue";
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";
import drawerChild from "@/utils/mixins/drawer-child";
import { log } from "util";
export default {
  mixins: [form, drawerChild],
  data() {
    return {
      DoctorServices: [],
      loadingButton: false,
      upload_image: false,
      tabAddRole: false,
      loadRole: false,
      roles: "",
      specialties: [],
      data: [],
      classes: [
        {
          type: "percent",
          name: "Процент",
        },
        {
          type: "amount",
          name: "Сумма",
        },
      ],
      services: [],
      form: {},
      fileList: [
        {
          name: "food.jpeg",
          url: "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100",
        },
      ],
      dialogImageUrl: "",
      dialogVisible: false,
      defaultProps: {
        children: "children",
        label: "label",
      },
      selectedList: [],
    };
  },
  async created() {
    await this.Roles();

    await this.servicesList({ relation: true });
    await this.specialtiesList();
    this.loadModel();
    this.form = JSON.parse(JSON.stringify(this.model));
    this.DoctorServices = this.form.doctor_services;
    // this.$refs.items.setCheckedKeys(this.DoctorServices);
  },
  computed: {
    ...mapGetters({
      model: "users/model",
      rules: "users/rules",
      services_list: "services/relation_list",
      columns: "users/columns",
      specialties_list: "specialties/list",
      spess: "users/spess",
    }),
  },
  methods: {
    ...mapActions({
      loadRoles: "roles/index",
      servicesList: "services/relationList",
      servicesRealtionList: "services/relationList",
      UserUpdate: "users/update",
      specialtiesList: "specialties/index",
    }),
    async afterOpened() {
      if (this.form.image) {
        this.form.image = this.url + this.form.image.image;
      }
      const query = { relation: true };
      await this.servicesRealtionList(query)
        .then((res) => {
          this.data = res.data.services;
        })
        .catch((err) => {});
      await this.form.doctor_services.forEach((serv) => {
        this.data.forEach((child) => {
          if (child.children.find((x) => x.id == serv.service_id)) {
            child.children.find((x) => x.id == serv.service_id).amount =
              serv.amount;
            child.children.find((x) => x.id == serv.service_id).amount_type =
              serv.amount_type;
          }
        });

        if (this.data.find((x) => x.id == serv.service_id)) {
          this.data.find((x) => x.id == serv.service_id).amount = serv.amount;
          this.data.find((x) => x.id == serv.service_id).amount_type =
            serv.amount_type;
        }

        if (this.$refs.items) {
          if (
            this.$refs.items.root.childNodes.find(
              (x) => x.data.id == serv.service_id
            )
          )
            this.$refs.items.root.childNodes.find(
              (x) => x.data.id == serv.service_id
            ).checked = true;

          this.$refs.items.root.childNodes.forEach((item) => {
            if (item.childNodes.find((x) => x.data.id == serv.service_id)) {
              item.childNodes.find(
                (x) => x.data.id == serv.service_id
              ).checked = true;
            }
          });
        }
      });
    },
    changes(node, data) {
      data.children.forEach((value) => {
        value.amount_type = data.amount_type,
        value.amount = data.amount
      })
    },
    afterClosed() {},
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.filterDoctorServices();
          var formDataUpdate = new FormData();
          for (var key in this.form) {
            if (this.form[key] != null) {
              formDataUpdate.append(key, this.form[key]);
            } else {
              formDataUpdate.append(key, "");
            }
          }
          formDataUpdate.append(
            "specialties",
            JSON.stringify(this.specialties)
          );
          formDataUpdate.append(
            "doctor_services",
            JSON.stringify(this.DoctorServices)
          );
          formDataUpdate.append(
            "services",
            JSON.stringify(this.$refs.items.getCheckedNodes())
          );
          formDataUpdate.append("_method", "PUT");
          this.loadingButton = true;
          this.UserUpdate({ data: formDataUpdate, id: this.form.id })
            .then((res) => {
              this.loadingButton = false;
              this.form = {};
              this.DoctorServices = [];
              this.$alert({
                success: true,
                message: res.message,
              });
              this.$parent.$parent.listChanged();
              this.resetForm("form");
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.indexOf(value) !== -1;
      return dd;
    },
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    selected() {
      this.selectedList = [];
      let item = this.$refs.items.getCheckedNodes();
      for (const i in item) {
        if (item[i]) {
          const element = item[i];
          if (element.children.length == 0) {
            this.selectedList.push(element);
          }
        }
      }
    },
    loadModel() {
      //   this.$refs.items.setCheckedKeys([]);
      this.form = JSON.parse(JSON.stringify(this.model));
      this.specialties = this.form.specialties;
      this.DoctorServices = this.form.doctor_services;
      //   this.$refs.items.setCheckedKeys(this.DoctorServices);
    },
    async Roles() {
      if (!this.loadRole) {
        this.loadRole = true;
        await this.loadRoles()
          .then((res) => {
            this.roles = res.data.roles;
            this.loadRole = false;
          })
          .catch((err) => {
            this.loadRole = false;
          });
      }
    },

    uploadImage(event) {
      if (event.target.files && event.target.files[0]) {
        this.form.image = event.target.files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
          $("#image").attr("src", e.target.result);
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerUpdate" });
      this.close();
    },
    filterDoctorServices() {
      this.selectedList.forEach((element) => {
        this.DoctorServices.push(element.id);
      });
    },
  },
  components: {
    AddRole,
  },
  watch: {
    model: {
      handler: async function () {
        if (this.roles == "") await this.Roles();
        if (this.specialties_list == []) await this.Specialties();
        await this.loadModel();
      },
      deep: true,
    },
  },
};
</script> 

<style>
.wrapper-block {
  background-image: url("/img/avatar.png");
  background-repeat: no-repeat;
  background-size: 80px auto;
  height: 100px;
  display: inline-block;
}

input[type="file"] {
  height: 100px;
  font-size: 0;
  -webkit-appearance: none;
  appearance: none;
}
input[type="file"]::-webkit-file-upload-button {
  width: 100px;
  height: 100px;
  opacity: 0;
  -webkit-appearance: none;
  appearance: none;
}
#custom-upload {
  display: inline-block;
}
#custom-upload img {
  height: 70px;
}
.el-input__inner2 {
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 32px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
}
.el-input__inner2:disabled {
  background: #f6f8fb;
}
</style>